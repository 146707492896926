@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.startReturnButton {
  margin-top: 10px;
}

.requiredLabel {
  display: block;
  margin-bottom: $space-md;
  font-size: $font-size-sm;
}
