@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$step-backward-icon-scale: scale(1) !default;

.container {
  padding: 10px 10px 100px;
}

.returnsLayout {
  max-width: 850px;
  margin: 0 auto;
}

.breadCrumbs {
  letter-spacing: 0.5px;
  font-size: $font-size-xs;
  max-width: 1088px;
  margin: 0 auto;
  border-bottom: 1px solid $color-border;
  padding: $space-xxs 0;
  margin-top: $space-sm;
  display: none;

  @include lg-min {
    display: block;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-transform: uppercase;
  margin: $space-xxxxs 10px $space-xs;

  @include lg-min {
    margin: $space-md 0;
  }

  h1 {
    font-size: $font-size-md;
    margin: 0;
    letter-spacing: $letter-spacing-headline;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;

  @include md-min {
    gap: 21px;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.stepContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;

  @include md-min {
    flex: 6;
    gap: 0;
  }
}

.needHelpContainer {
  display: flex;
  flex-direction: column;
  gap: $space-xs;
}

.rightContainer {
  @include md-min {
    flex: 4;
  }
}

.stepBackwardIcon {
  transform: $step-backward-icon-scale;
}
